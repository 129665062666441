import { useGetUserQuery } from '~/graphql/generated/schema'

export const getStaffOverride = (email: string): boolean => {
  if (email.endsWith('@lumoslabs.com')) {
    return true
  }
  if (email.endsWith('@testlio.com')) {
    return true
  }

  return false
}

const useStaffUserOverride = (): boolean => {
  const { data } = useGetUserQuery({ fetchPolicy: 'cache-first' })
  return getStaffOverride(data?.me?.email || '')
}

export default useStaffUserOverride
