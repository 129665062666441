import { createContext, useContext, useMemo } from 'react'

import { useReactiveVar } from '@apollo/client'
import keyBy from 'lodash/keyBy'

import QueryLoader from '~/components/QueryLoader'
import PageLoader from '~/components/ui/PageLoader'
import { GamesLibraryDictionary } from '~/gameConfigs/GamesLibrary'
import { useGlobalGamesConfigQuery } from '~/graphql/generated/schema'
import { currentUserVar } from '~/graphql/reactive-vars'

type GameFromBackend = { slug: GameSlug; areaSlug: AreaSlugType; englishFluency: boolean }
interface GlobalGameConfigValues {
  gameList: GameFromBackend[]
  gameDict: Partial<Record<GameSlug, GameFromBackend>>
}

const GlobalGameConfigContext = createContext<GlobalGameConfigValues>({
  gameList: [],
  gameDict: {},
})

export const useGlobalGameConfig = () => {
  return useContext(GlobalGameConfigContext)
}

export const GlobalGameConfigProvider: React.FC = ({ children }) => {
  const currentUser = useReactiveVar(currentUserVar)
  const { data, loading, error } = useGlobalGamesConfigQuery({ skip: !currentUser })

  const extractedData = useMemo(() => {
    const _gameList: GlobalGameConfigValues['gameList'] = []
    if (!data?.games) return { _gameList, _gameDict: {} }

    data.games.forEach?.((game) => {
      // filter all games that are not in the GamesLibraryDictionary
      if (GamesLibraryDictionary[game.slug as GameSlug]) {
        _gameList.push({
          slug: game.slug as GameSlug,
          areaSlug: game.areaSlug as AreaSlugType,
          englishFluency: game.englishFluency, // we don't use this yet
        })
      }
    })

    const _gameDict = keyBy(_gameList, 'slug') as GlobalGameConfigValues['gameDict']
    return { _gameList, _gameDict }
  }, [data])

  return (
    <GlobalGameConfigContext.Provider
      value={{
        gameList: extractedData._gameList,
        gameDict: extractedData._gameDict,
      }}
    >
      {currentUser ? (
        <QueryLoader loading={loading} error={error} data={data} LoadingComponent={<PageLoader />}>
          {children}
        </QueryLoader>
      ) : (
        children
      )}
    </GlobalGameConfigContext.Provider>
  )
}
