import clsx from 'clsx'

interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  kind?: 'primary' | 'secondary' | 'alert' | 'text'
  disabled?: boolean
}

export const Button = (props: ButtonProps) => {
  const { className, children, kind = 'primary', ...rest } = props || {}

  const isPrimary = kind === 'primary'
  const isSecondary = kind === 'secondary'
  const isAlert = kind === 'alert'
  const isText = kind === 'text'

  return (
    <button
      {...rest}
      className={clsx(
        'text-body-text-2 font-bold',
        'inline-flex items-center justify-center gap-2 box-border',
        'py-[14px] px-7 rounded-full',
        'cursor-pointer pointer-events-auto disabled:pointer-events-none',

        isPrimary && 'border-0',
        isPrimary && 'bg-interactive-filled-default hover:bg-primary-50 disabled:bg-surface-subdued',
        isPrimary && 'text-text-on-interactive disabled:text-text-disabled',

        isSecondary && 'border border-border-default disabled:border-interactive-filled-disabled',
        isSecondary && 'bg-surface-default hover:bg-neutral-warm-95 disabled:bg-surface-subdued',
        isSecondary && 'text-text-interactive hover:text-primary-50 disabled:text-text-disabled',

        isAlert && 'border-0',
        isAlert && 'bg-interactive-alert-default hover:bg-interactive-alert-hovered disabled:bg-surface-subdued',
        isAlert && 'text-text-on-interactive disabled:text-text-disabled',

        isText && 'border-0',
        isText && 'bg-transparent disabled:bg-surface-subdued',
        isText && 'text-text-interactive hover:text-primary-50 disabled:text-text-disabled',

        className,
      )}
    >
      {children}
    </button>
  )
}
