import { useEffect } from 'react'

import { useCookies } from 'react-cookie'

export const L2UserCookieOptions = { domain: '.lumosity.com', path: '/', secure: true, sameSite: 'none' } as const
export const L2UserCookieName = 'L2User'

const useSetL2UserCookie = () => {
  const [, setCookie, removeCookie] = useCookies()

  useEffect(() => {
    if (window.location.hostname === 'app.lumosity.com') {
      setCookie(L2UserCookieName, true, L2UserCookieOptions)
    }
  }, [removeCookie, setCookie])
}

export default useSetL2UserCookie
