import { RequestHandler } from '@apollo/client'
import { NetworkError } from '@apollo/client/errors'
import { ErrorLink } from '@apollo/client/link/error'
import cookie from 'cookie'
import type { GraphQLError } from 'graphql/error/GraphQLError'

import { LUMOSITY_USER_COOKIE, PLATFORM_WEB } from '~/constants'
import { ageGateVar, forceLogoutVar } from '~/graphql/reactive-vars'
import dayjs from '~/libs/dayjs'
import logger from '~/utils/logger'

/**
 * GraphQL Error Code strings
 * @see https://www.apollographql.com/docs/apollo-server/data/errors/#error-codes
 */
export enum GraphQLErrorCode {
  GRAPHQL_PARSE_FAILED = 'GRAPHQL_PARSE_FAILED',
  GRAPHQL_VALIDATION_FAILED = 'GRAPHQL_VALIDATION_FAILED',
  BAD_USER_INPUT = 'BAD_USER_INPUT',
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  FORBIDDEN = 'FORBIDDEN',
  PERSISTED_QUERY_NOT_FOUND = 'PERSISTED_QUERY_NOT_FOUND',
  PERSISTED_QUERY_NOT_SUPPORTED = 'PERSISTED_QUERY_NOT_SUPPORTED',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  INVALID_SESSION = 'INVALID_SESSION',
  INVALID_BEARER_TOKEN = 'INVALID_BEARER_TOKEN',
  UNDERAGE_DATE_OF_BIRTH = 'UNDERAGE_DATE_OF_BIRTH',
  MISSING_DATE_OF_BIRTH = 'MISSING_DATE_OF_BIRTH',
}

/**
 * Request handler that attaches to header a user JWT stored in a cookie.
 * @param operation GraphQL operation requested (query, mutation, etc.).
 * @param forward Next Link in link chain.
 * @returns Invocation of next link on operation.
 *
 * @see https://www.apollographql.com/docs/react/networking/authentication/#header
 */
export const authHandler: RequestHandler = (operation, forward) => {
  const cookies = cookie.parse(document.cookie)
  const userToken = cookies?.[LUMOSITY_USER_COOKIE]

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: userToken ? `Bearer ${userToken}` : null,
    },
  }))

  return forward(operation)
}

/**
 * Request handler that attaches headers required by lumos-backend.
 * @param operation GraphQL operation requested (query, mutation, etc.).
 * @param forward Next Link in link chain.
 * @returns Invocation of next link on operation.
 */
export const requiredHeadersHandler: RequestHandler = (operation, forward) => {
  const timeZoneOffset = dayjs().format('Z')
  const localeWithPrefix = navigator?.language || 'en-US'
  const cookies = cookie.parse(document.cookie)
  const preferredLanguage = cookies?.['NEXT_LOCALE'] || null
  // send application locale, if not available then send browser locale
  // todo - ideally we should be sending application locale always
  const locale = preferredLanguage ? preferredLanguage : localeWithPrefix

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      Platform: PLATFORM_WEB,
      Locale: locale,
      TimeZoneOffset: timeZoneOffset,
    },
  }))

  return forward(operation)
}

/**
 * Logs formatted error message from a GraphQL error to the console.
 * @param err GraphQLError thrown by operation
 * @param type Type of error to log
 */
export const logGraphQLError = (err: GraphQLError, type: GraphQLErrorCode): void => {
  if (
    [
      GraphQLErrorCode.MISSING_DATE_OF_BIRTH,
      GraphQLErrorCode.UNDERAGE_DATE_OF_BIRTH,
      GraphQLErrorCode.INVALID_SESSION,
      GraphQLErrorCode.INVALID_BEARER_TOKEN,
    ].includes(type)
  ) {
    return // Do not log these errors
  }
  const title = type.split('_').join(' ')

  const message = `${title}: ${err.message}, Location: ${err.locations}, Path: ${err.path}`
  logger.error('🛑 [GraphQL Error]', message, err)
}

/**
 * Logs formatted error message from a NetworkError to the console.
 * @param err NetworkError thrown during operation
 */
export const logNetworkError = (err: NetworkError): void => {
  const message = `${err?.message}`
  logger.clientError('📶 [Network Error]', message, err)
}

/**
 * Error handler used to create an ApolloLink for application level error handling.
 * Should handle observability concerns.
 * @param param0 {ErrorResponse} ErrorResponse object
 */
export const apolloErrorHandler: ErrorLink.ErrorHandler = ({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      switch (err.extensions.code) {
        // TODO: If necessary, handle individual error cases
        case GraphQLErrorCode.BAD_USER_INPUT:
          break
        case GraphQLErrorCode.FORBIDDEN:
          break
        case GraphQLErrorCode.GRAPHQL_PARSE_FAILED:
          break
        case GraphQLErrorCode.GRAPHQL_VALIDATION_FAILED:
          break
        case GraphQLErrorCode.INTERNAL_SERVER_ERROR:
          break
        case GraphQLErrorCode.PERSISTED_QUERY_NOT_FOUND:
          break
        case GraphQLErrorCode.PERSISTED_QUERY_NOT_SUPPORTED:
          break
        case GraphQLErrorCode.INVALID_BEARER_TOKEN:
        case GraphQLErrorCode.INVALID_SESSION: {
          forceLogoutVar(true)
          break
        }
        case GraphQLErrorCode.MISSING_DATE_OF_BIRTH: {
          ageGateVar('MISSING_DATE_OF_BIRTH')
          break
        }
        case GraphQLErrorCode.UNDERAGE_DATE_OF_BIRTH: {
          ageGateVar('UNDERAGE_DATE_OF_BIRTH')
          break
        }
        case GraphQLErrorCode.UNAUTHENTICATED:
          // Potential opportunity to refresh token and retry??
          // see example: https://www.apollographql.com/docs/react/data/error-handling/#on-graphql-errors
          break
      }
      logGraphQLError(err, err.extensions.code)
    }
  }
  if (networkError) {
    logNetworkError(networkError)
  }
}
