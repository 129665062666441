import { useCallback, useContext, useEffect, useRef } from 'react'

import getConfig from 'next/config'
import { useRouter } from 'next/router'
import { useCookies } from 'react-cookie'

import { AuthContext } from '~/context/AuthContext'
import { useGetUserQuery } from '~/graphql/generated/schema'
import { useLogout } from '~/hooks/useAuth'
import { L2UserCookieName, L2UserCookieOptions } from '~/hooks/useSetL2UserCookie'
import { getStaffOverride } from '~/hooks/useStaffUserOverride'
const { publicRuntimeConfig } = getConfig()

const RESEARCH_ROLE = 'research'
const FLAGSHIP_USER_ROLE = 'flagship_user'
const FLAGSHIP_URL = publicRuntimeConfig.auth.flagship.url

export function useResearchRedirect() {
  const router = useRouter()
  const { data, loading, error } = useGetUserQuery({ fetchPolicy: 'cache-and-network' })
  const isLogoutProcessing = useRef<boolean>(false)
  const { processOryLogout } = useLogout()
  const { setHasPremium, setIsFreeTrialEligible } = useContext(AuthContext)
  const [, setCookie] = useCookies()

  // logout l2_user, keep flagship cookie & redirect to flagship
  const logoutAndRedirect = useCallback(async () => {
    if (isLogoutProcessing.current) return
    isLogoutProcessing.current = true
    if (window.location.hostname === 'app.lumosity.com') {
      // check useSetL2UserCookie.tsx for more details
      setCookie(L2UserCookieName, false, L2UserCookieOptions)
    }
    await processOryLogout({ logoutFlagship: false })
    router.push(FLAGSHIP_URL)
  }, [processOryLogout, setCookie, router])

  useEffect(() => {
    const hasPremium = data?.me?.hasPremium || false
    const isFreeTrialEligible = data?.me?.isFreeTrialEligible || false

    const isStaffUser = getStaffOverride(data?.me?.email || '')
    // if it's not a staff user, we use the subscription state from the server
    if (!isStaffUser) {
      setHasPremium(hasPremium)
      setIsFreeTrialEligible(isFreeTrialEligible)
      return
    }

    // for a staff user, we allow overriding the subscription state
    const overrideSubscriptionState = localStorage.getItem('override_subscription_state') || '' // '' | 'free' | 'premium' | 'free_trial_eligible'
    if (overrideSubscriptionState === 'free') {
      setHasPremium(false)
      setIsFreeTrialEligible(false)
    } else if (overrideSubscriptionState === 'premium') {
      setHasPremium(true)
      setIsFreeTrialEligible(false)
    } else if (overrideSubscriptionState === 'free_trial_eligible') {
      setHasPremium(false)
      setIsFreeTrialEligible(true)
    } else {
      setHasPremium(hasPremium)
      setIsFreeTrialEligible(isFreeTrialEligible)
    }
  }, [data?.me?.hasPremium, data?.me?.isFreeTrialEligible, data?.me?.email, setHasPremium, setIsFreeTrialEligible])

  if (data) {
    const { roles } = data.me
    if (roles.includes(RESEARCH_ROLE) || roles.includes(FLAGSHIP_USER_ROLE)) {
      logoutAndRedirect()
      // slight overload of the term-- we're not loading roles anymore, but we are about to start loading a new
      // page, so caller should keep behaving as if we're loading until the redirect takes effect
      return { loading: true, data: undefined, error: undefined }
    }
  }

  return { loading, data, error }
}
